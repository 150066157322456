export default [
  {
    "id": "Dateline Standard Time",
    "displayName": "(UTC-12:00) International Date Line West",
    "offset": -12
  },
  {
    "id": "UTC-11",
    "displayName": "(UTC-11:00) Coordinated Universal Time-11",
    "offset": -11
  },
  {
    "id": "Aleutian Standard Time",
    "displayName": "(UTC-10:00) Aleutian Islands",
    "offset": -9
  },
  {
    "id": "Hawaiian Standard Time",
    "displayName": "(UTC-10:00) Hawaii",
    "offset": -10
  },
  {
    "id": "Marquesas Standard Time",
    "displayName": "(UTC-09:30) Marquesas Islands",
    "offset": -9.5
  },
  {
    "id": "Alaskan Standard Time",
    "displayName": "(UTC-09:00) Alaska",
    "offset": -8
  },
  {
    "id": "UTC-09",
    "displayName": "(UTC-09:00) Coordinated Universal Time-09",
    "offset": -9
  },
  {
    "id": "Pacific Standard Time (Mexico)",
    "displayName": "(UTC-08:00) Baja California",
    "offset": -7
  },
  {
    "id": "UTC-08",
    "displayName": "(UTC-08:00) Coordinated Universal Time-08",
    "offset": -8
  },
  {
    "id": "Pacific Standard Time",
    "displayName": "(UTC-08:00) Pacific Time (US & Canada)",
    "offset": -7
  },
  {
    "id": "US Mountain Standard Time",
    "displayName": "(UTC-07:00) Arizona",
    "offset": -7
  },
  {
    "id": "Mountain Standard Time (Mexico)",
    "displayName": "(UTC-07:00) La Paz, Mazatlan",
    "offset": -7
  },
  {
    "id": "Mountain Standard Time",
    "displayName": "(UTC-07:00) Mountain Time (US & Canada)",
    "offset": -6
  },
  {
    "id": "Yukon Standard Time",
    "displayName": "(UTC-07:00) Yukon",
    "offset": -7
  },
  {
    "id": "Central America Standard Time",
    "displayName": "(UTC-06:00) Central America",
    "offset": -6
  },
  {
    "id": "Central Standard Time",
    "displayName": "(UTC-06:00) Central Time (US & Canada)",
    "offset": -5
  },
  {
    "id": "Easter Island Standard Time",
    "displayName": "(UTC-06:00) Easter Island",
    "offset": -5
  },
  {
    "id": "Central Standard Time (Mexico)",
    "displayName": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "offset": -6
  },
  {
    "id": "Canada Central Standard Time",
    "displayName": "(UTC-06:00) Saskatchewan",
    "offset": -6
  },
  {
    "id": "SA Pacific Standard Time",
    "displayName": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    "offset": -5
  },
  {
    "id": "Eastern Standard Time (Mexico)",
    "displayName": "(UTC-05:00) Chetumal",
    "offset": -5
  },
  {
    "id": "Eastern Standard Time",
    "displayName": "(UTC-05:00) Eastern Time (US & Canada)",
    "offset": -4
  },
  {
    "id": "Haiti Standard Time",
    "displayName": "(UTC-05:00) Haiti",
    "offset": -4
  },
  {
    "id": "Cuba Standard Time",
    "displayName": "(UTC-05:00) Havana",
    "offset": -4
  },
  {
    "id": "US Eastern Standard Time",
    "displayName": "(UTC-05:00) Indiana (East)",
    "offset": -4
  },
  {
    "id": "Turks And Caicos Standard Time",
    "displayName": "(UTC-05:00) Turks and Caicos",
    "offset": -4
  },
  {
    "id": "Paraguay Standard Time",
    "displayName": "(UTC-04:00) Asuncion",
    "offset": -4
  },
  {
    "id": "Atlantic Standard Time",
    "displayName": "(UTC-04:00) Atlantic Time (Canada)",
    "offset": -3
  },
  {
    "id": "Venezuela Standard Time",
    "displayName": "(UTC-04:00) Caracas",
    "offset": -4
  },
  {
    "id": "Central Brazilian Standard Time",
    "displayName": "(UTC-04:00) Cuiaba",
    "offset": -4
  },
  {
    "id": "SA Western Standard Time",
    "displayName": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "offset": -4
  },
  {
    "id": "Pacific SA Standard Time",
    "displayName": "(UTC-04:00) Santiago",
    "offset": -3
  },
  {
    "id": "Newfoundland Standard Time",
    "displayName": "(UTC-03:30) Newfoundland",
    "offset": -2.5
  },
  {
    "id": "Tocantins Standard Time",
    "displayName": "(UTC-03:00) Araguaina",
    "offset": -3
  },
  {
    "id": "E. South America Standard Time",
    "displayName": "(UTC-03:00) Brasilia",
    "offset": -3
  },
  {
    "id": "SA Eastern Standard Time",
    "displayName": "(UTC-03:00) Cayenne, Fortaleza",
    "offset": -3
  },
  {
    "id": "Argentina Standard Time",
    "displayName": "(UTC-03:00) City of Buenos Aires",
    "offset": -3
  },
  {
    "id": "Montevideo Standard Time",
    "displayName": "(UTC-03:00) Montevideo",
    "offset": -3
  },
  {
    "id": "Magallanes Standard Time",
    "displayName": "(UTC-03:00) Punta Arenas",
    "offset": -3
  },
  {
    "id": "Saint Pierre Standard Time",
    "displayName": "(UTC-03:00) Saint Pierre and Miquelon",
    "offset": -2
  },
  {
    "id": "Bahia Standard Time",
    "displayName": "(UTC-03:00) Salvador",
    "offset": -3
  },
  {
    "id": "UTC-02",
    "displayName": "(UTC-02:00) Coordinated Universal Time-02",
    "offset": -2
  },
  {
    "id": "Greenland Standard Time",
    "displayName": "(UTC-02:00) Greenland",
    "offset": -1
  },
  {
    "id": "Mid-Atlantic Standard Time",
    "displayName": "(UTC-02:00) Mid-Atlantic - Old",
    "offset": -1
  },
  {
    "id": "Azores Standard Time",
    "displayName": "(UTC-01:00) Azores",
    "offset": 0
  },
  {
    "id": "Cape Verde Standard Time",
    "displayName": "(UTC-01:00) Cabo Verde Is.",
    "offset": -1
  },
  {
    "id": "UTC",
    "displayName": "(UTC) Coordinated Universal Time",
    "offset": 0
  },
  {
    "id": "GMT Standard Time",
    "displayName": "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    "offset": 1
  },
  {
    "id": "Greenwich Standard Time",
    "displayName": "(UTC+00:00) Monrovia, Reykjavik",
    "offset": 0
  },
  {
    "id": "Sao Tome Standard Time",
    "displayName": "(UTC+00:00) Sao Tome",
    "offset": 0
  },
  {
    "id": "Morocco Standard Time",
    "displayName": "(UTC+01:00) Casablanca",
    "offset": 0
  },
  {
    "id": "W. Europe Standard Time",
    "displayName": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "offset": 2
  },
  {
    "id": "Central Europe Standard Time",
    "displayName": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "offset": 2
  },
  {
    "id": "Romance Standard Time",
    "displayName": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "offset": 2
  },
  {
    "id": "Central European Standard Time",
    "displayName": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "offset": 2
  },
  {
    "id": "W. Central Africa Standard Time",
    "displayName": "(UTC+01:00) West Central Africa",
    "offset": 1
  },
  {
    "id": "GTB Standard Time",
    "displayName": "(UTC+02:00) Athens, Bucharest",
    "offset": 3
  },
  {
    "id": "Middle East Standard Time",
    "displayName": "(UTC+02:00) Beirut",
    "offset": 3
  },
  {
    "id": "Egypt Standard Time",
    "displayName": "(UTC+02:00) Cairo",
    "offset": 2
  },
  {
    "id": "E. Europe Standard Time",
    "displayName": "(UTC+02:00) Chisinau",
    "offset": 3
  },
  {
    "id": "West Bank Standard Time",
    "displayName": "(UTC+02:00) Gaza, Hebron",
    "offset": 2
  },
  {
    "id": "South Africa Standard Time",
    "displayName": "(UTC+02:00) Harare, Pretoria",
    "offset": 2
  },
  {
    "id": "FLE Standard Time",
    "displayName": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "offset": 3
  },
  {
    "id": "Israel Standard Time",
    "displayName": "(UTC+02:00) Jerusalem",
    "offset": 3
  },
  {
    "id": "South Sudan Standard Time",
    "displayName": "(UTC+02:00) Juba",
    "offset": 2
  },
  {
    "id": "Kaliningrad Standard Time",
    "displayName": "(UTC+02:00) Kaliningrad",
    "offset": 2
  },
  {
    "id": "Sudan Standard Time",
    "displayName": "(UTC+02:00) Khartoum",
    "offset": 2
  },
  {
    "id": "Libya Standard Time",
    "displayName": "(UTC+02:00) Tripoli",
    "offset": 2
  },
  {
    "id": "Namibia Standard Time",
    "displayName": "(UTC+02:00) Windhoek",
    "offset": 2
  },
  {
    "id": "Jordan Standard Time",
    "displayName": "(UTC+03:00) Amman",
    "offset": 3
  },
  {
    "id": "Arabic Standard Time",
    "displayName": "(UTC+03:00) Baghdad",
    "offset": 3
  },
  {
    "id": "Syria Standard Time",
    "displayName": "(UTC+03:00) Damascus",
    "offset": 3
  },
  {
    "id": "Turkey Standard Time",
    "displayName": "(UTC+03:00) Istanbul",
    "offset": 3
  },
  {
    "id": "Arab Standard Time",
    "displayName": "(UTC+03:00) Kuwait, Riyadh",
    "offset": 3
  },
  {
    "id": "Belarus Standard Time",
    "displayName": "(UTC+03:00) Minsk",
    "offset": 3
  },
  {
    "id": "Russian Standard Time",
    "displayName": "(UTC+03:00) Moscow, St. Petersburg",
    "offset": 3
  },
  {
    "id": "E. Africa Standard Time",
    "displayName": "(UTC+03:00) Nairobi",
    "offset": 3
  },
  {
    "id": "Volgograd Standard Time",
    "displayName": "(UTC+03:00) Volgograd",
    "offset": 3
  },
  {
    "id": "Iran Standard Time",
    "displayName": "(UTC+03:30) Tehran",
    "offset": 3.5
  },
  {
    "id": "Arabian Standard Time",
    "displayName": "(UTC+04:00) Abu Dhabi, Muscat",
    "offset": 4
  },
  {
    "id": "Astrakhan Standard Time",
    "displayName": "(UTC+04:00) Astrakhan, Ulyanovsk",
    "offset": 4
  },
  {
    "id": "Azerbaijan Standard Time",
    "displayName": "(UTC+04:00) Baku",
    "offset": 4
  },
  {
    "id": "Russia Time Zone 3",
    "displayName": "(UTC+04:00) Izhevsk, Samara",
    "offset": 4
  },
  {
    "id": "Mauritius Standard Time",
    "displayName": "(UTC+04:00) Port Louis",
    "offset": 4
  },
  {
    "id": "Saratov Standard Time",
    "displayName": "(UTC+04:00) Saratov",
    "offset": 4
  },
  {
    "id": "Georgian Standard Time",
    "displayName": "(UTC+04:00) Tbilisi",
    "offset": 4
  },
  {
    "id": "Caucasus Standard Time",
    "displayName": "(UTC+04:00) Yerevan",
    "offset": 4
  },
  {
    "id": "Afghanistan Standard Time",
    "displayName": "(UTC+04:30) Kabul",
    "offset": 4.5
  },
  {
    "id": "West Asia Standard Time",
    "displayName": "(UTC+05:00) Ashgabat, Tashkent",
    "offset": 5
  },
  {
    "id": "Qyzylorda Standard Time",
    "displayName": "(UTC+05:00) Astana",
    "offset": 5
  },
  {
    "id": "Ekaterinburg Standard Time",
    "displayName": "(UTC+05:00) Ekaterinburg",
    "offset": 5
  },
  {
    "id": "Pakistan Standard Time",
    "displayName": "(UTC+05:00) Islamabad, Karachi",
    "offset": 5
  },
  {
    "id": "India Standard Time",
    "displayName": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "offset": 5.5
  },
  {
    "id": "Sri Lanka Standard Time",
    "displayName": "(UTC+05:30) Sri Jayawardenepura",
    "offset": 5.5
  },
  {
    "id": "Nepal Standard Time",
    "displayName": "(UTC+05:45) Kathmandu",
    "offset": 5.75
  },
  {
    "id": "Central Asia Standard Time",
    "displayName": "(UTC+06:00) Bishkek",
    "offset": 6
  },
  {
    "id": "Bangladesh Standard Time",
    "displayName": "(UTC+06:00) Dhaka",
    "offset": 6
  },
  {
    "id": "Omsk Standard Time",
    "displayName": "(UTC+06:00) Omsk",
    "offset": 6
  },
  {
    "id": "Myanmar Standard Time",
    "displayName": "(UTC+06:30) Yangon (Rangoon)",
    "offset": 6.5
  },
  {
    "id": "SE Asia Standard Time",
    "displayName": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "offset": 7
  },
  {
    "id": "Altai Standard Time",
    "displayName": "(UTC+07:00) Barnaul, Gorno-Altaysk",
    "offset": 7
  },
  {
    "id": "W. Mongolia Standard Time",
    "displayName": "(UTC+07:00) Hovd",
    "offset": 7
  },
  {
    "id": "North Asia Standard Time",
    "displayName": "(UTC+07:00) Krasnoyarsk",
    "offset": 7
  },
  {
    "id": "N. Central Asia Standard Time",
    "displayName": "(UTC+07:00) Novosibirsk",
    "offset": 7
  },
  {
    "id": "Tomsk Standard Time",
    "displayName": "(UTC+07:00) Tomsk",
    "offset": 7
  },
  {
    "id": "China Standard Time",
    "displayName": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "offset": 8
  },
  {
    "id": "North Asia East Standard Time",
    "displayName": "(UTC+08:00) Irkutsk",
    "offset": 8
  },
  {
    "id": "Singapore Standard Time",
    "displayName": "(UTC+08:00) Kuala Lumpur, Singapore",
    "offset": 8
  },
  {
    "id": "W. Australia Standard Time",
    "displayName": "(UTC+08:00) Perth",
    "offset": 8
  },
  {
    "id": "Taipei Standard Time",
    "displayName": "(UTC+08:00) Taipei",
    "offset": 8
  },
  {
    "id": "Ulaanbaatar Standard Time",
    "displayName": "(UTC+08:00) Ulaanbaatar",
    "offset": 8
  },
  {
    "id": "Aus Central W. Standard Time",
    "displayName": "(UTC+08:45) Eucla",
    "offset": 8.75
  },
  {
    "id": "Transbaikal Standard Time",
    "displayName": "(UTC+09:00) Chita",
    "offset": 9
  },
  {
    "id": "Tokyo Standard Time",
    "displayName": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "offset": 9
  },
  {
    "id": "North Korea Standard Time",
    "displayName": "(UTC+09:00) Pyongyang",
    "offset": 9
  },
  {
    "id": "Korea Standard Time",
    "displayName": "(UTC+09:00) Seoul",
    "offset": 9
  },
  {
    "id": "Yakutsk Standard Time",
    "displayName": "(UTC+09:00) Yakutsk",
    "offset": 9
  },
  {
    "id": "Cen. Australia Standard Time",
    "displayName": "(UTC+09:30) Adelaide",
    "offset": 10.5
  },
  {
    "id": "AUS Central Standard Time",
    "displayName": "(UTC+09:30) Darwin",
    "offset": 9.5
  },
  {
    "id": "E. Australia Standard Time",
    "displayName": "(UTC+10:00) Brisbane",
    "offset": 10
  },
  {
    "id": "AUS Eastern Standard Time",
    "displayName": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "offset": 11
  },
  {
    "id": "West Pacific Standard Time",
    "displayName": "(UTC+10:00) Guam, Port Moresby",
    "offset": 10
  },
  {
    "id": "Tasmania Standard Time",
    "displayName": "(UTC+10:00) Hobart",
    "offset": 11
  },
  {
    "id": "Vladivostok Standard Time",
    "displayName": "(UTC+10:00) Vladivostok",
    "offset": 10
  },
  {
    "id": "Lord Howe Standard Time",
    "displayName": "(UTC+10:30) Lord Howe Island",
    "offset": 11
  },
  {
    "id": "Bougainville Standard Time",
    "displayName": "(UTC+11:00) Bougainville Island",
    "offset": 11
  },
  {
    "id": "Russia Time Zone 10",
    "displayName": "(UTC+11:00) Chokurdakh",
    "offset": 11
  },
  {
    "id": "Magadan Standard Time",
    "displayName": "(UTC+11:00) Magadan",
    "offset": 11
  },
  {
    "id": "Norfolk Standard Time",
    "displayName": "(UTC+11:00) Norfolk Island",
    "offset": 12
  },
  {
    "id": "Sakhalin Standard Time",
    "displayName": "(UTC+11:00) Sakhalin",
    "offset": 11
  },
  {
    "id": "Central Pacific Standard Time",
    "displayName": "(UTC+11:00) Solomon Is., New Caledonia",
    "offset": 11
  },
  {
    "id": "Russia Time Zone 11",
    "displayName": "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    "offset": 12
  },
  {
    "id": "New Zealand Standard Time",
    "displayName": "(UTC+12:00) Auckland, Wellington",
    "offset": 13
  },
  {
    "id": "UTC+12",
    "displayName": "(UTC+12:00) Coordinated Universal Time+12",
    "offset": 12
  },
  {
    "id": "Fiji Standard Time",
    "displayName": "(UTC+12:00) Fiji",
    "offset": 12
  },
  {
    "id": "Kamchatka Standard Time",
    "displayName": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "offset": 13
  },
  {
    "id": "Chatham Islands Standard Time",
    "displayName": "(UTC+12:45) Chatham Islands",
    "offset": 13.75
  },
  {
    "id": "UTC+13",
    "displayName": "(UTC+13:00) Coordinated Universal Time+13",
    "offset": 13
  },
  {
    "id": "Tonga Standard Time",
    "displayName": "(UTC+13:00) Nuku'alofa",
    "offset": 13
  },
  {
    "id": "Samoa Standard Time",
    "displayName": "(UTC+13:00) Samoa",
    "offset": 13
  },
  {
    "id": "Line Islands Standard Time",
    "displayName": "(UTC+14:00) Kiritimati Island",
    "offset": 14
  }
]